import { get, post } from "@/utils/http";

export const productAPI = {
  get: function(paramObj) {
    return get("product", paramObj);
  },
  getOne: function(paramObj) {
    return get(`product/${paramObj}`);
  },
  post: function(paramObj) {
    return post("product", paramObj);
  },
  getPurchaseList: function(paramObj) {
    return get("purchase", paramObj);
  },
  getGivewayList: function(paramObj) {
    return get("giveway", paramObj);
  }
};
