<template>
  <div class="Complete">
    <v-container fluid class="px-0 pt-0">
      <v-row justify="center">
        <v-col cols="12" md="4" class="mt-10">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h2>交易完成，您已成為VIP會員！</h2>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-col>
                <span>訂單編號：{{ order_id }}</span>
              </v-col>
              <v-col>
                <span>會員編號：{{ member_id }}</span>
              </v-col>
              <v-col>
                <span>總價：{{ total }}</span>
              </v-col>
              <v-col>
                <span>PV：{{ pv }}</span>
              </v-col>
              <v-col>
                <span>信箱：{{ email }}</span>
              </v-col>
              <v-col>
                <span>地址：{{ address }}</span>
              </v-col>
              <v-col class="d-flex justify-center">
                <v-btn color="primary" to="/login"> 按此登入 </v-btn>
              </v-col>
              <v-col class="d-flex justify-center">
                <span>*密碼預設為手機號碼</span>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/utils/loading";
import axios from "axios";

export default {
  name: "Complete",
  data() {
    return {
      order_id: "",
      member_id: "",
      total: "",
      pv: "",
      email: "",
      address: "",
    };
  },
  created() {
    const url = new URL(window.location);
    let order_id = url.searchParams.get("order_id");

    showLoading();
    axios
      .post(
        `http://web.hilary.com.tw:8085/getapi?type=GET_ORDERID&order_id=${order_id}`
      )
      .then((res) => {
        this.order_id = order_id;
        this.member_id = res.data.data[0].member_id;
        this.email = res.data.data[0].email;
        this.total = res.data.data[0].order_total;
        this.pv = res.data.data[0].order_pv_total;
        this.address = res.data.data[0].address;
      })
      .catch((err) => {
        console.log("error", err);
        this.$Message.error("查無此訂單編號!");
      });
    hideLoading();
  },
};
</script>

<style scoped>
span {
  color: #000;
  font-size: 18px;
}
</style>
