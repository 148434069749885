<template>
  <div class="result">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-stepper alt-labels v-model="currentStep">
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1">
                確認購物車
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="currentStep > 2" step="2">
                填寫資料
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="currentStep > 3" step="3">
                完成訂購
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <!-- 步驟三(完成訂購)--Start -->
              <v-stepper-content step="3">
                <v-row justify="center" align="center">
                  <v-col cols="12" md="6" v-if="!isError">
                    <v-img
                      src="@/assets/images/mall/success.png"
                      max-width="100"
                      class="ma-auto mb-3"
                    ></v-img>
                    <div class="text-center">
                      <h2 class="mb-3 grey--text">
                        已經收到您的訂單，並已發出訂單確認信件到信箱
                      </h2>
                      <h1 class="mb-3">
                        訂單編號：{{ $route.params.order_id }}
                      </h1>
                      <h3 class="mb-3 red--text">
                        《 如要領取 VIP 分紅請上傳身分證正反面及銀行帳戶 》
                      </h3>
                      <h3 class="mb-3 red--text">
                        請拍照、或儲存網址以便日後查詢。
                      </h3>
                    </div>
                    <v-row>
                      <v-col>
                        <v-card>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    商品
                                  </th>
                                  <th class="text-center">
                                    數量
                                  </th>
                                  <th class="text-center">
                                    價錢
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="item in order_info"
                                  :key="item.id"
                                  class="text-center"
                                >
                                  <td>
                                    <v-row
                                      justify="center"
                                      justify-md="start"
                                      align="center"
                                    >
                                      <h3>
                                        {{ item.item_name }}
                                      </h3>
                                    </v-row>
                                  </td>
                                  <td>
                                    <v-chip label color="white">
                                      {{ item.item_num }}
                                    </v-chip>
                                  </td>
                                  <td>
                                    <h3 class="red--text">
                                      {{ item.item_total }}
                                    </h3>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" v-if="isError">
                    <h1 class="red--text text-center">
                      訂單編號錯誤，請再次確認
                    </h1>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <!-- 步驟三(完成訂購)--End -->
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { checkoutPublicAPI } from "@/api/checkout/api";

export default {
  data() {
    return {
      isError: false,
      currentStep: 3,
      order_info: []
    };
  },
  created() {
    checkoutPublicAPI
      .get_order_info(this.$route.params.order_id)
      .then(res => {
        this.order_info = res.data.data;
      })
      .catch(err => {
        this.isError = true;
      });
  }
};
</script>
<style lang="scss">
.result
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: unset;
}
</style>
