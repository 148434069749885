import { get, post } from "@/utils/http";

export const checkoutPublicAPI = {
  get_check_value: function(paramObj) {
    return post("checkout", paramObj, {
      headers: { requiresAuth: false }
    });
  },
  prep_creater_order_info: function(paramObj) {
    return post("checkout/prep", paramObj, {
      headers: { requiresAuth: false, showLoading: false }
    });
  },
  get_order_info: function(order_id, paramObj) {
    return get(`checkout/esafe/receive/${order_id}`, paramObj, {
      headers: { requiresAuth: false }
    });
  },
  get_service_center: function(paramObj) {
    return get("service_center", paramObj, {
      headers: { requiresAuth: false }
    });
  }
};
