import { get, post } from "@/utils/http";

export const shopcartPublicAPI = {
  getShopCart: function(cart_id, paramObj) {
    return post(`shopcart/${cart_id}`, paramObj, {
      headers: { requiresAuth: false }
    });
  }
};

export const shopcartAPI = {
  createShopCartUrl: function(paramObj) {
    return post("shopcart", paramObj);
  },
  createShopCartInfo: function(cart_id, paramObj) {
    return post(`shopcartinfo/${cart_id}`, paramObj);
  },
  getPromo: function(promo_code, paramObj) {
    return get(`promo/${promo_code}`, paramObj, {
      headers: { repuiresAuth: false }
    });
  }
};
