<template>
  <div class="checkout">
    <v-container fluid>
      <v-row justify="center" align="center">
        <!-- 驗證手機號碼--Start -->
        <v-col cols="12" md="6" v-if="userCart.length == 0">
          <v-card outlined>
            <v-card-title>
              <h2 class="mx-auto">購物車手機號碼認證</h2>
            </v-card-title>
            <v-card-text>
              <v-text-field :value="$route.params.cart_id" label="購物車ID" outlined readonly></v-text-field>
              <v-text-field v-model="userPhoneNumber" label="手機號碼" outlined></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" x-large block class="mx-auto" @click="getdbShopCart()"> 送出 </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-dialog max-width="800px" v-model="privacyDialong">
          <v-card>
            <v-card-title>
              <span>關於台灣本島訂購說明</span>
            </v-card-title>
            <v-card-text>
              <p>
                喜來麗國際股份有限公司極為重視個人資料之安全與隱私，本公司所取得之資料均由您主動提供，且本公司會予以保密。
                <br />
                <br />
                就您提供之資料，我們將用於提供給您更好的服務。我們將使用您的個人資料於下列之目的:回應您對特定健康資訊、產品、服務之要求；為您提供特定產品或服務之個人化服務;與您聯絡，以及其他您已經同意之用途範圍。
                <br />
                <br />
                在未獲得您許可之情況下，我們不會將您的姓名、住址、電子郵件信箱或任何其他個人資料轉交給第三方，除非該第三人是本公司之下包商或代理人，協助或代理本公司提供產品或服務給您。
                <br />
                <br />
                但也提醒您；請勿在網路上公開透露您的個人資料，因該資料有可能會被他人蒐集和使用，特別是在網路上公開的發言場合。
                <br />
                <br />
                一、隱私權保護政策的適用範圍
                <br />
                隱私權保護政策內容，包括喜來麗國際股份有限公司如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。隱私權保護政策內容，包括喜來麗國際股份有限公司如何處理在您使用網站服務時收集到的個人識喜來麗國際股份有限公司極為重視個人資料之安全與隱私，本公司所取得之資料均由您主動提供，且本公司會予以保密。
                <br />
                <br />
              </p>
              <v-checkbox v-model="agreePrivacy">
                <template v-slot:label>
                  <div>我已經閱讀並同意隱私權與條款</div>
                </template>
              </v-checkbox>
              <v-btn color="primary mr-2" @click="privacyAgree">確定</v-btn>
              <v-btn @click="privacyDialong = false">取消</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- 驗證手機號碼--End -->
        <v-col v-if="userCart.length != 0">
          <v-stepper alt-labels v-model="currentStep">
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1"> 確認購物車 </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="currentStep > 2" step="2"> 填寫資料 </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="currentStep > 3" step="3"> 完成訂購 </v-stepper-step>
            </v-stepper-header>
            <v-row>
              <v-col>
                <h4 v-if="currentStep == 1">訂單內容</h4>
                <h4 v-if="currentStep == 2">訂購人資訊</h4>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-stepper-items>
              <!-- 步驟一(訂單內容)--Start -->
              <v-form ref="checkoutForm1" v-model="formValid1" class="pa-0 ma-0">
                <v-stepper-content step="1" class="pa-0">
                  <v-row justify="center">
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">商品</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in userCart" :key="item.product_name" class="text-center">
                                  <td>
                                    <v-row justify="center" justify-md="start" align="center">
                                      <v-img max-width="100" max-height="100" :src="item.product_photo" class="ma-1">
                                      </v-img>
                                      <h3>
                                        {{ item.product_name }}
                                      </h3>
                                    </v-row>
                                  </td>
                                  <td>
                                    <v-chip label color="white">
                                      {{ item.amount }}
                                    </v-chip>
                                  </td>
                                  <td>
                                    <h3 class="red--text">
                                      {{
                                        item.store_name == '加購區' || item.store_name == '贈品區'
                                          ? item.price
                                          : item.price * item.amount
                                      }}
                                    </h3>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <h4>配送國家</h4>
                          <v-col class="pl-0">
                            <v-radio-group v-model="userCountry" row :rules="rules.required">
                              <v-radio label="台灣" value="Taiwan"> </v-radio>
                              <v-radio label="海外" value="Overseas"> </v-radio>
                            </v-radio-group>
                          </v-col>
                          <p>通訊地址</p>
                          <v-select
                            v-if="cityName"
                            item-text="name"
                            :items="cityName"
                            v-model="userCity"
                            placeholder="請選擇縣市"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <v-select
                            v-if="userCity"
                            item-text="name"
                            :items="cityArea"
                            v-model="userArea"
                            placeholder="請選擇區域"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <v-text-field
                            v-if="userArea"
                            v-model="cityZip"
                            placeholder="請選擇區碼"
                            filled
                            dense
                            readonly
                            :rules="rules.required"
                          >
                          </v-text-field>
                          <v-text-field
                            v-model="userAddress"
                            name="userAddress"
                            placeholder="請輸入您的地址"
                            class="pt-2"
                            :rules="rules.required"
                          >
                          </v-text-field>
                          <p>付款方式</p>
                          <v-select
                            v-model="userPayment"
                            :items="payment"
                            placeholder="請選擇付款方式"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <div v-if="userPayment == '信用卡付款'">
                            <p>分期期數</p>
                            <v-select
                              v-model="userTerm"
                              :items="cardTerm"
                              placeholder="請選擇分期期數"
                              filled
                              dense
                              :rules="rules.required"
                            >
                            </v-select>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <h4>關於台灣本島訂購說明</h4>
                          <v-card outlined class="">
                            <p>
                              付款方式<br />
                              (1)信用卡付款<br />
                              (2)ATM轉帳<br />
                              -<br />
                              ●選擇轉帳及匯款的客戶請於下訂單3天內完成繳費，並聯絡客服轉告轉帳帳戶後五碼及轉帳金額。<br />
                              ● 確認收到款項後，商品將於7天內寄出(不含假日)。<br />
                            </p>
                            <!-- 閱讀完整內容--Start -->
                            <v-row justify="center">
                              <v-dialog v-model="dialog" width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn block color="primary" v-bind="attrs" v-on="on"> 閱讀完整內容 </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title>
                                    <span>關於台灣本島訂購說明</span>
                                  </v-card-title>
                                  <v-card-text>
                                    付款方式<br />

                                    (1)信用卡付款<br />
                                    (2)ATM轉帳<br />
                                    -<br />
                                    ●選擇轉帳及匯款的客戶請於下訂單3天內完成繳費，並聯絡客服轉告轉帳帳戶後五碼及轉帳金額。<br />
                                    ● 確認收到款項後，商品將於7天內寄出(不含假日)。<br /><br />

                                    寄送方式<br />
                                    宅配/新竹物流<br />
                                    海外/國際快遞 (順豐)<br />
                                    運費問題<br />
                                    ●宅配<br />
                                    新竹以北 100元<br />
                                    高雄以北150<br />
                                    屏東、花蓮 東部地區 200<br />
                                    偏鄉費用另計<br />
                                    ●宅配寄送若超過5公斤須拆單訂購。<br /><br />

                                    商品退換貨說明<br />
                                    【退貨】<br />
                                    欲辦理退貨，請務必保持包裝全新完整，保持商品、附件、包裝、廠商紙箱、明細、發票、所有隨付文件或資料之完整性，且以公平交易及多層次傳直銷管理辦法及事業手冊相關辦法辦理，否則恕不接受退貨。<br />
                                    ●請於購買7日內於服務時間聯繫客服以利辦理退貨。<br />
                                    ●本公司收到退回之完整商品及相關文件後，若經確認無誤將於14個工作天內處理退款/刷退之程序(含信用卡交易)。<br /><br />

                                    【換貨】<br />
                                    ●請於收到商品當下立即檢查商品是否正確及完整，若有出貨錯誤、與訂購內容不符、異常等狀況，請於24小時內以電話或傳真通知喜來麗，並於7日內完成辦理，請保留出貨明細，並協助拍下瑕疵部分，回傳客服，客服將於14日內協助換貨事宜。<br /><br />

                                    (請務必保持包裝全新完整，保持商品、附件、包裝、廠商紙箱、明細、發票、所有隨付文件或資料之完整性，否則恕不接受換貨)<br />
                                    -<br />
                                    ●退換貨客戶需自行負擔寄回本公司的運費。<br />
                                    ●因購物者所提供資料不完整無法即時聯繫，或因個人因素無法收件等導致損失，恕不接受退換貨。<br />
                                    ●喜來麗保有接受訂單與否之權利。<br /><br />

                                    取消訂單或更改訂單<br />
                                    ●訂單送出後即無法修改訂購內容，欲取消訂單或更改訂單內容，請於服務時間洽詢客服人員，喜來麗將協助您取消訂單或更改訂單。<br />
                                    ●加入購物車的商品不代表為您立即保留庫存數量，須完成結帳動作才能確保商品的保留及追加。<br /><br />

                                    訂單查詢<br />
                                    ●欲查詢訂單進度，請至訂單查詢內，輸入信箱與訂單編號，即可查看訂單明細。<br />
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-row>
                            <!-- 閱讀完整內容--End -->
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col>
                      <h4>訂單資訊</h4>
                      <v-card outlined>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>
                              運送地
                              <span v-if="userCity"> => [{{ userCity }}] </span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>小計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right"> NT.{{ orderAmount }} </v-col>
                        </v-row>
                        <v-row justify="space-between">
                          <v-col class="px-5 py-0">
                            <p>運費</p>
                          </v-col>
                          <v-col class="px-5 py-0 text-right"> NT.{{ orderShipping }} </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>合計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right"> NT.{{ orderTotal }} </v-col>
                        </v-row>
                        <v-col>
                          <v-btn block color="primary" @click="nextStep()"> 前往結帳 </v-btn>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-form>
              <!-- 步驟一(訂單內容)--Start -->

              <!-- 步驟二(填寫資料))--Start -->
              <!-- 洪陽金流API -->
              <v-form
                ref="checkoutForm2"
                v-model="formValid2"
                class="pa-0 ma-0"
                action="https://test.esafe.com.tw/Service/Etopm.aspx"
                method="POST"
                @submit.prevent="prepCreateOrderInfo($event)"
              >
                <v-stepper-content step="2" class="pa-0">
                  <v-row justify="center">
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col cols="12" md="6">
                          <label for="userName">姓名</label>
                          <v-text-field
                            v-model="userName"
                            name="userName"
                            id="userName"
                            placeholder="請輸入真實姓名"
                            :rules="rules.name"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <label for="userPhoneNumber">電話</label>
                          <v-text-field
                            v-model="userPhoneNumber"
                            name="userPhoneNumber"
                            id="userPhoneNumber"
                            placeholder="請輸入您的電話"
                            :rules="rules.phoneNumber"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <label for="userMail">信箱 *如需更改信箱或是產品，請返回重新操作</label>
                          <v-text-field
                            v-model="userMail"
                            name="userMail"
                            id="userMail"
                            placeholder="請輸入您的信箱"
                            :rules="rules.emailRules"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <!-- <h3>營業處</h3>
                          <v-select
                            v-model="userServiceCenter"
                            :items="serviceCenterList"
                            item-text="Name"
                            item-value="ID"
                            placeholder="請選擇營業處"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select> -->
                          <h3>發票類型</h3>
                          <v-select
                            v-model="userInvoice"
                            :items="invoiceType"
                            placeholder="請選擇付款方式"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <label for="compilation" v-if="userInvoice == '公司-二聯式發票'"
                            >統編 *公司行號加入才可輸入統編</label
                          >
                          <v-text-field
                            v-if="userInvoice == '公司-二聯式發票'"
                            v-model="compilation"
                            name="compilation"
                            id="compilation"
                            placeholder="輸入您的統編"
                            :rules="rules.taxId"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col>
                      <h4>訂單資訊</h4>
                      <v-card outlined>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>
                              運送地
                              <span v-if="userCity"> => [{{ userCity }}] </span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>小計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right"> NT.{{ orderAmount }} </v-col>
                        </v-row>
                        <v-row justify="space-between">
                          <v-col class="px-5 py-0">
                            <p>運費</p>
                          </v-col>
                          <v-col class="px-5 py-0 text-right"> NT.{{ orderShipping }} </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>合計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right"> NT.{{ orderTotal }} </v-col>
                        </v-row>
                        <v-col>
                          <input type="hidden" name="web" v-model="storeNo" />
                          <input type="hidden" name="MN" v-model="orderTotal" />
                          <input type="hidden" name="OrderInfo" v-model="orderInfo" />
                          <input type="hidden" name="TD" v-model="orderNo" />
                          <input type="hidden" name="sna" v-model="userName" />
                          <input type="hidden" name="sdt" v-model="userPhoneNumber" />
                          <input type="hidden" name="email" v-model="userMail" />
                          <input type="hidden" name="UserNo" v-model="userPhoneNumber" />
                          <input
                            v-if="userPayment == '信用卡付款' || userTerm != ''"
                            type="hidden"
                            name="Term"
                            v-model="userTerm"
                          />
                          <input
                            v-if="userPayment == '信用卡付款' || userTerm != ''"
                            type="hidden"
                            name="Card_Type"
                            value="0"
                          />
                          <input type="hidden" name="ChkValue" v-model="ChkValue" />
                          <div v-for="(item, index) in userCart" :key="item.id">
                            <input type="hidden" :name="`ProductName${index + 1}`" v-model="item.product_name" />
                            <input type="hidden" :name="`ProductPrice${index + 1}`" v-model="item.price" />
                            <input type="hidden" :name="`ProductQuantity${index + 1}`" v-model="item.amount" />
                          </div>
                          <input type="hidden" name="DueDate" v-model="DueDate" />
                          <v-btn v-if="!formValid2" block color="primary" @click="checkOut"> 前往結帳  </v-btn>
                          <v-btn v-if="formValid2" block color="primary" type="submit"> 前往結帳  </v-btn>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-form>
              <!-- 步驟二(填寫資料))--End -->
              <!-- 步驟三(完成訂購)--Start -->
              <v-stepper-content step="3"> </v-stepper-content>
              <!-- 步驟三(完成訂購)--End -->
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import cityName from '@/assets/taiwan_districts.json';
import { checkoutPublicAPI } from '@/api/checkout/api';
import { shopcartPublicAPI } from '@/api/shopcart/api';
import { showLoading, hideLoading } from '@/utils/loading';
import axios from 'axios';

export default {
  data() {
    return {
      cardStorge: [],
      isLoading: false,
      userCart: [],
      formValid1: true,
      formValid2: true,
      rules: {
        required: [(value) => !!value || '必填欄位'],
        name: [(v) => !!v || '必填欄位', (v) => /^[\u4e00-\u9fa5]+$/.test(v) || '只能輸入中文'],
        emailRules: [(v) => /.+@.+/.test(v) || '請輸入正確電子信箱'],
        phoneNumber: [(v) => (v && v.length >= 10) || '請輸入正確的手機號碼'],
        taxId: [
          (v) => !!v || '必填欄位',
          (v) => /^[0-9]*$/.test(v) || '請輸入正確的統編號碼',
          (v) => v.length >= 8 || '請輸入正確的統編號碼',
        ],
      },
      currentStep: 1,
      dialog: false,
      privacyDialong: false,
      recipient_id: '',
      userCountry: '',
      cityName,
      cityArea: '',
      cityZip: '',
      payment: ['信用卡付款', 'ATM轉帳'],
      cardTerm: ['0', '6', '12'],
      invoiceType: ['一般發票', '公司-二聯式發票'],
      compilation: '',
      serviceCenterList: [
        {
          ID: '0',
          Name: '無',
        },
      ],
      userCity: '',
      userArea: '',
      userZip: '',
      userAddress: '',
      userPayment: '',
      userName: '',
      userPhoneNumber: '',
      userMail: '',
      userInvoice: '',
      userServiceCenter: '',
      agreePrivacy: '',
      // 紅陽支付
      storeNo: '', // 商店代號
      // storeNo: 'S2010069026',
      orderNo: '', // 商家訂單編號
      orderInfo: '', // 交易內容
      DueDate: '', // 條碼付款繳費期限（當天 + 3 天)
      ChkValue: '', // 交易檢查碼
      userTerm: '', // 分期期數
    };
  },
  created() {
    // 產生繳費期限
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    let tmpDueDate = new Date();
    this.DueDate = tmpDueDate.addDays(3).toISOString().slice(0, 10).replaceAll('-', '');
    // 載入營業處
    // checkoutPublicAPI.get_service_center().then((res) => {
    //   let allowList = res.data.data.allowList.split(",");
    //   allowList.forEach((element, index) => {
    //     this.serviceCenterList[index + 1] = Object.assign(
    //       {},
    //       ...res.data.data.centerList.filter((item) => item.ID == element)
    //     );
    //   });
    //   this.$set(this.serviceCenterList, this.serviceCenterList);
    // });
  },
  methods: {
    // getdbShopCart() {
    //   shopcartPublicAPI
    //     .getShopCart(this.$route.params.cart_id, {
    //       recipient_phone: this.userPhoneNumber,
    //     })
    //     .then((res) => {
    //       if (this.userCart.length == 0) {
    //         res.data.data.forEach((element, index) => {
    //           this.userCart.push(element.item_info);
    //           this.userCart[index].amount = element.item_num;
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       this.$Message.error("查無此購物車連結");
    //     });
    // },
    getdbShopCart() {
      showLoading();
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETSHOPCART', {
          cart_id: this.$route.params.cart_id,
          recipient_phone: this.userPhoneNumber,
        })
        .then((res) => {
          this.cardStorge = res.data.data;
          //if (!res.success) return console.log(res);
          this.service_center_id = res.data.service_center_id;
          this.recipient_id = res.data.recipient_id;
          this.userCart = this.userCart.sort((a, b) => a.id - b.id);
          this.agree;
          hideLoading();
          this.privacyDialong = true;
        })
        .catch((err) => {
          console.log('error', err);
          hideLoading();
          this.$Message.error('查無此購物車連結');
        });
    },
    privacyAgree() {
      if (!this.agreePrivacy) return this.$Message.error('請先閱讀並同意隱私權與條款');
      if (!this.cardStorge) {
        return this.$Message.error('查無此購物車連結/鏈結已過期');
      }
      if (this.userCart.length == 0) {
        this.cardStorge.forEach((element, index) => {
          this.userCart.push(element);
          this.userCart[index].price_origin = element.price;
        });
      }
      this.privacyDialong = false;
    },
    nextStep() {
      if (this.$refs.checkoutForm1.validate()) {
        this.currentStep++;
      }
    },
    checkOut() {
      this.$refs.checkoutForm2.validate();
    },
    prepCreateOrderInfo(e) {
      if (!this.isLoading) {
        showLoading();
        this.isLoading = true;
        // 產生訂單號碼(會員編號 + YYYYMMDD + 隨機三碼流水號)
        this.orderNo =
          'N' +
          this.userPhoneNumber.slice(2) +
          new Date().toISOString().slice(0, 10).replaceAll('-', '') +
          Math.floor(Date.now()).toString().substr(-3);
        this.userCart.forEach((element) => {
          setTimeout(() => {
            axios
              .post('http://web.hilary.com.tw:8085/getapi?type=ORDER_INFO', {
                // checkoutPublicAPI.prep_creater_order_info({
                order_id: this.orderNo,
                order_total: this.orderTotal,
                order_pv_total: this.orderPvTotal,
                member_id: this.recipient_id,
                name: this.userName,
                phone: this.userPhoneNumber,
                email: this.userMail,
                address: this.userZip + this.userCity + this.userArea + this.userAddress,
                creator_id: this.$store.state.userInfo.member_id,
                service_center_id: parseInt(this.service_center_id),
                item_id: element.id != '' ? element.id : '',
                item_name: element.product_name != '' ? element.product_name : '',
                // item_num: element.amount,
                item_total: element.price * element.amount,
                amount: element.amount,
                set_id: element.set_id != '' ? element.set_id : '',
                set_name: element.set_name != '' ? element.set_name : '',
                esafe_id: this.storeNo,
                // esafe_id: 'S2010069026',
                esafe_check_value: this.ChkValue,
                payment_mode: this.userPayment,
                // invoiceType: this.invoiceType,
                invoiceType: this.userInvoice,
                compilation: this.compilation,
                cart_id: this.$route.params.cart_id,
              })
              .then((res) => console.log(res))
              .catch((e) => {
                console.log(e);
                this.$Message.error('建立訂單失敗');
                return;
              });
          });
        }, 200);
        setTimeout(() => {
          axios.post('http://web.hilary.com.tw:8085/getapi?type=EMAIL3', {
            member_id: this.recipient_id,
            name: this.userName,
            phone: this.userPhoneNumber,
            email: this.userMail,
            address: this.userZip + this.userCity + this.userArea + this.userAddress,
          });
          e.target.submit();
        }, 5000);
      }
    },
  },
  computed: {
    orderAmount() {
      let all_money = 0;
      this.userCart.forEach((item) => {
        if (item.store_name == 'VIP商城' || item.store_name == '重銷商城') all_money += item.amount * item.price;
        else all_money += item.price;
      });
      return all_money;
    },
    orderShipping() {
      let all_money = 100;
      if (this.orderAmount == 0) {
        return (all_money = 0);
      } else {
        return (all_money = 0);
      }
    },
    orderTotal() {
      let all_money = 0;
      all_money += this.orderAmount + this.orderShipping;
      return all_money;
    },
    orderPvTotal() {
      let all_pv = 0;
      this.userCart.forEach((item) => {
        all_pv += item.amount * item.pv;
      });
      return all_pv;
    },
  },
  watch: {
    userCity(val) {
      this.cityArea = cityName.find((city) => city.name === this.userCity).districts;
      this.userZip = '';
    },
    userArea(val) {
      let index = this.cityArea.findIndex((city) => city.name == this.userArea);
      this.cityZip = this.cityArea[index].zip;
    },
    userPayment() {
      // 判斷分期期數並更新購物車中的金額，確保消費者點擊完分期期數後又跳出導致金額落差
      this.userTerm = '';
      this.userCart.forEach((element) => {
        element.price = element.price_origin;
      });
      // checkoutPublicAPI
      //   .get_check_value({
      //     payment_mode: this.userPayment,
      //     order_total_price: this.orderTotal,
      //     card_term: this.userTerm == "0" ? "" : this.userTerm,
      //   })
      showLoading();
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETVAL', {
          payment_mode: this.userPayment,
          order_total_price: this.orderTotal,
          card_term: this.userTerm == '0' ? '' : this.userTerm,
        })
        .then((res) => {
          this.storeNo = res.data.storeNo; //商店ID
          // this.storeNo = 'S2010069026';
          this.ChkValue = res.data.ChkValue; //交易碼
          this.orderInfo = '';
          this.userCart.forEach((element) => {
            this.orderInfo += `${element.product_name} x ${element.amount} & `;
          });
          this.orderInfo = this.orderInfo.slice(0, -2);
          hideLoading();
          console.log(res.data.storeNo);
        });
    },
    userTerm() {
      // 定義分期數
      let term = '';
      switch (this.userTerm) {
        case '6':
          term = 'price_six';
          break;
        case '12':
          term = 'price_twelve';
          break;
        default:
          term = 'price_origin';
          break;
      }
      // 判斷分期期數並更新購物車中的金額
      showLoading();
      this.userCart.forEach((element) => {
        if (element.store_name == '重銷商城' || element.store_name == 'VIP商城') {
          axios
            .post('http://web.hilary.com.tw:8085/getapi?type=GETTERM', {
              product_id: element.id,
            })
            .then((res) => {
              console.log(res.data.data[0][term]);
              element.price = res.data.data[0][term];
            });
        }
      });
      // this.userCart.forEach((element) => {
      //   element.price = element[term];
      // });
      // // checkoutPublicAPI
      // //   .get_check_value({
      // //     payment_mode: this.userPayment,
      // //     order_total_price: this.orderTotal,
      // //     card_term: this.userTerm == "0" ? "" : this.userTerm,
      // //   })
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETVAL', {
          payment_mode: this.userPayment,
          order_total_price: this.orderTotal,
          card_term: this.userTerm == '0' ? '' : this.userTerm,
        })
        .then((res) => {
          this.storeNo = res.data.storeNo;
          // this.storeNo = 'S2010069026';
          this.ChkValue = res.data.ChkValue;
          this.orderInfo = '';
          this.userCart.forEach((element) => {
            this.orderInfo += `${element.product_name} x ${element.amount} & `;
          });
          this.orderInfo = this.orderInfo.slice(0, -2);
        });
      hideLoading();
    },
  },
};
</script>

<style lang="scss">
.checkout {
  padding: 5%;
}
.checkout .v-stepper,
.checkout .v-stepper__header,
.checkout .v-stepper__content {
  box-shadow: unset !important;
}
.checkout .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: unset;
}
</style>
