import { get, post } from "@/utils/http";

export const userAPI = {
  getCustomerList: function(paramObj) {
    return get("user/customer_list", paramObj);
  },
  getOrderList: function(paramObj) {
    return get("user/order", paramObj, { headers: { showLoading: false } });
  },
  getOrderInfo: function(order_id, paramObj) {
    return get(`user/order/${order_id}`, paramObj, {
      headers: { showLoading: false }
    });
  },
  getIdentification: function(paramObj) {
    return get(`user/upload/identification`, paramObj);
  },
  uploadIdentification: function(paramObj) {
    return post(`user/upload/identification`, paramObj);
  },
  getUserAccount: function(paramObj) {
    return get(`user/account`, paramObj);
  },
  updateUserAccount: function(paramObj) {
    return post(`user/account`, paramObj);
  },

};
